import Heading from './Heading';
import AppStore from '../assets/appStore.svg';
import PlayStore from '../assets/playStore.png';

const TryIt = () => {
  return (
    <div
      className='try-it'
      data-aos='fade-up'
      data-aos-delay='100'
      data-aos-duration='1000'
      data-aos-once='true'
    >
      <Heading subtitle='Our App' title='Try Training Tracker For Free Today' />
      <h4>We think you'll love it.</h4>
      <div className='stores'>
        <a target="_blank" href='https://apps.apple.com/us/app/training-tracker-workout-log/id1559791115'>
          <img src={AppStore} alt='appstore' />
        </a>
        <a target="_blank" href='https://play.google.com/store/apps/details?id=com.testapprealm'>
          <img src={PlayStore} alt='playstore' />
        </a>
      </div>
      <hr />
      <h5>Follow us on</h5>
      <div className='social-media'>
        <a
          data-aos='fade-up'
          data-aos-delay='100'
          data-aos-duration='1000'
          data-aos-once='true'
          href='https://www.facebook.com/Trainingtrackerapp/'
          target='_blank'
        >
          
          <div className='fb'>
           <i className='fab fa-facebook-f'></i>
          </div>
        </a>
        <a 
        href='https://twitter.com/_Trainingtrackr'
        target='_blank'
        >
          <div
            data-aos='fade-up'
            data-aos-delay='250'
            data-aos-duration='1000'
            data-aos-once='true'
            className='tw'
          >
            <i className='fab fa-twitter'></i>
          </div>
        </a>
        <a 
        href=' https://www.instagram.com/trainingtracker.app/'
        target='_blank'>
          <div
            data-aos='fade-up'
            data-aos-delay='400'
            data-aos-duration='1000'
            data-aos-once='true'
            className='inst'
            
          >
            <i className='fab fa-instagram'></i>
          </div>
        </a>
      </div>
    </div>
  );
};

export default TryIt;
