import { Container, Row, Col } from 'react-bootstrap';

import Iphone from '../assets/iphoneBody.png';
import AppVideo from '../assets/Video3.mp4';
import AppStore from '../assets/appStore.svg';
import PlayStore from '../assets/playStore.png';

const Header = () => {
  return (
    <div>
      <div className='header'>
        <Container>
          <Row>
            <Col md={5}>
              <div
                className='phone-container'
                data-aos='fade-up'
                data-aos-delay='100'
                data-aos-duration='1000'
                data-aos-once='true'
              >
                <img src={Iphone} alt='iphone' />
                <div className='video'>
                  <video height='100%' width='100%' autoPlay muted loop>
                    <source src={AppVideo} type='video/mp4' />
                  </video>
                </div>
              </div>
            </Col>
            <Col md={7}>
              <div className='header-content'>
                <p>
                  Training Tracker is designed to simply the task of logging your work outs and
                  tracking your progress. Clean, simple screen layouts allow effortless interaction
                  regardless of the users fitness level of training knowledge
                </p>
                <h4>
                  <i className='fas fa-dumbbell'></i> <span>Create Workouts</span>
                </h4>
                <p>Use the provided workouts or add and modify your own custom workouts.</p>
                <h4>
                  <i className='fas fa-walking'></i> <span>Add Exercises</span>
                </h4>
                <p>
                  Use the provided exercises or build your own exercise database for your own
                  workouts.
                </p>
                <h4>
                  <i className='far fa-chart-bar'></i> <span>Track Progress</span>
                </h4>
                <p>
                  Easily monitior your progress with set based 1RM information and 1RM record alerts
                  for every exercise as well as calendar based volume tracking for each workout or
                  individual exercise.
                </p>
              </div>
              <div className='header-animation'></div>
              <Container className='stores py-3' style={{display:'flex'}}>
                <a target="_blank" href='https://apps.apple.com/us/app/training-tracker-workout-log/id1559791115'>
                  <img src={AppStore} alt='appstore' />
                </a>
                <a target="_blank" href='https://play.google.com/store/apps/details?id=com.testapprealm'>
                  <img src={PlayStore} alt='playstore' />
                </a>
              </Container>
            </Col>

          </Row>
        </Container>
      </div>

    </div>
  );
};

export default Header;
