// import { Button } from 'react-bootstrap';
import { useState, useEffect } from "react";

import Logo from '../assets/logo.png';

const Navbar = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    setShow(window.pageYOffset > 10);
  };
  return (
    <nav className={`Nav ${show && 'Nav__black'} navbar`}>
      <img src={Logo} alt='logo' />
      {/* <a href='!#'>
        <Button variant='primary'>Download</Button>
      </a> */}
    </nav>
  );
};

export default Navbar;
