import Logo from '../assets/logo.png';

const Footer = () => {
  return (
    <footer>
      <img src={Logo} alt='logo' />
      <p>Copyright © 2021 Training Tracker</p>
    </footer>
  );
};

export default Footer;
