import { Container, Row, Col } from 'react-bootstrap';

import Heading from './Heading';
import Iphone from '../assets/iphoneBody.png';
import AppVideo from '../assets/Video1.mp4';
import AppVideo2 from '../assets/Video2.mp4';
import AppVideo3 from '../assets/Video4.mp4';

const VideoInteraction = () => {
  return (
    <div
      className='my-md-5 pt-md-5 VideoInteraction-div'
      data-aos='fade-up'
      data-aos-delay='100'
      data-aos-duration='1000'
      data-aos-once='true'
    >
      <Heading subtitle='Interaction' title='App Video Demo' />
      <Container className='Video-Container'>
        <Row>
        <Col md={4} className='Col Col3'>
          <h2>Enter and edit your own workouts and exercises to fit your personal needs.</h2>
          <div className='Phone-div'
            data-aos='fade-up'
            data-aos-delay='100'
            data-aos-duration='1000'
            data-aos-once='true'
            >
             <div className='phone-container2'>
                <img src={Iphone} className='Phone-Frame' alt='iphone' />
                <div className='video2'>
                  <video height='100%' width='100%' autoPlay muted loop>
                    <source src={AppVideo3} type='video/mp4' />
                  </video>
                </div>
              </div>
              </div>
          </Col>
          <Col md={4} className='Col'>
          <h2>Select a date to view your workout stats on that day. Graph the results to quickly see progress over past weeks or months.</h2>
            <div className='Phone-div'
            data-aos='fade-up'
            data-aos-delay='100'
            data-aos-duration='1000'
            data-aos-once='true'
            >
             <div className='phone-container2'>
                <img src={Iphone} className='Phone-Frame' alt='iphone' />
                <div className='video2'>
                  <video height='100%' width='100%' autoPlay muted loop>
                    <source src={AppVideo} type='video/mp4' />
                  </video>
                </div>
              </div>
              </div>
          </Col>
          <Col md={4} className='Col'>
          <h2> Monitor your body composition by entering your weight and/or body fat measurements to track your progress over time.</h2>
          <div className='Phone-div'
            data-aos='fade-up'
            data-aos-delay='100'
            data-aos-duration='1000'
            data-aos-once='true'
            >
             <div className='phone-container2'>
                <img src={Iphone} className='Phone-Frame' alt='iphone' />
                <div className='video2'>
                  <video height='100%' width='100%' autoPlay muted loop>
                    <source src={AppVideo2} type='video/mp4' />
                  </video>
                </div>
              </div>
              </div>
          </Col>   
        </Row>
      </Container>
    </div>
  );
};

export default VideoInteraction;
