const Heading = ({ subtitle, title }) => {
  return (
    <div className='heading'>
      <span>{subtitle}</span>
      <h2>{title}</h2>
    </div>
  );
};

export default Heading;
