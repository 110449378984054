import { useState } from "react";
import { Container, Row, Alert } from 'react-bootstrap';

import Heading from './Heading';

const Contact = () => {
  const [name, setname] = useState('')
  const [email, setemail] = useState('')
  const [message, setmessage] = useState('')

  const OnSend = () => {
    if (name === '' || email === '' || message === '') {
      console.log('fill fields')
      return alert('Fill All Fields Correctly')
    } else {
      fetch('https://02f432720a80.ngrok.io/api/emailmessage', {
        method: 'POST',
        body: JSON.stringify({
          name: name,
          email: email,
          message: message
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => res.json())
        .then(data => {
          console.log(data)
        })
    }
  }

  return (
    <div
      id='contactUs'
      data-aos='fade-up'
      data-aos-delay='100'
      data-aos-duration='1000'
      data-aos-once='true'
    >
      <Heading subtitle='Contact Us' title='Questions or Feedback' />
      <Container>
        <Row className='Contact-Form-div'>
          <div className='contact-form'>
            <input value={name} onChange={e => setname(e.target.value)} type='text' placeholder='Name' />
            <input value={email} onChange={e => setemail(e.target.value)} type='email' placeholder='Email' />
            <input value={message} onChange={e => setmessage(e.target.value)} className='textarea' as='textarea' rows={4} placeholder='Your message...' />
            <button variant='primary' onClick={() => OnSend()}>Send</button>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Contact;
