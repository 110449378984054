import { useEffect } from 'react';
import AOS from 'aos';

import Navbar from '../components/Navbar';
import Header from '../components/Header';
import VideoInteraction from '../components/VideoInteraction';
import Contact from '../components/Contact';
import TryIt from '../components/TryIt';
import Footer from '../components/Footer';

const Home = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <Navbar />
      <Header />
      <VideoInteraction />
      <Contact />
      <TryIt />
      <Footer />
    </>
  );
};

export default Home;
